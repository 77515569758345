// import TencentCloudChat from "@tencentcloud/chat";
import TencentCloudChat from "@tencentcloud/chat/index.es.js"
import SignalingModule from '@tencentcloud/chat/modules/signaling-module.js';
import GroupModule from '@tencentcloud/chat/modules/group-module.js';

import TIMUploadPlugin from "tim-upload-plugin";
import $store from "@/store";
import { message_received } from '@/common/js/IM-message.js'
import { onSignalingListener, offSignalingListener } from '@/common/js/IM-Signaling.js'
import { imLogout } from '@/common/js/userJs'
import { conversationListUpdated } from '@/common/js/IM-message.js'

import {
  MessageBox
} from "element-ui";
import eventHub from '@/utils/eventHub'

// 如果您使用 uni-app 开发 app，并有推送需求，请使用 [TIMPush](https://cloud.tencent.com/document/product/269/103522)
//挂载到全局对象，方便使用
window.TIM_TYPES = TencentCloudChat.TYPES;
window.TIM_EVENT = TencentCloudChat.EVENT;
window.TSignaling = TencentCloudChat.TSignaling;
export function im_init(userId, userSig) {
  // 创建 SDK 实例，TencentCloudChat.create() 方法对于同一个 SDKAppID 只会返回同一份实例
  let options = {
    SDKAppID: $store.state.chat.trtcOptions.sdkAppId, // 接入时需要将0替换为您的即时通信应用的 SDKAppID
    modules: {
      'signaling-module': SignalingModule, // 信令
      'group-module': GroupModule, // 群和群成员
    }
  };
  //挂载到全局对象，方便使用
  window.TIMchat = TencentCloudChat.create(options); // SDK 实例通常用 TIMchat 表示
  // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
  /**
   * 0 普通级别，日志量较多，接入时建议使用
   * 1 release级别，SDK 输出关键信息，生产环境时建议使用
   * 2 告警级别，SDK 只输出告警和错误级别的日志
   * 3 错误级别，SDK 只输出错误级别的日志
   * 4 无日志级别，SDK 将不打印任何日志
   */
  TIMchat.setLogLevel(0);

  // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
  TIMchat.registerPlugin({
    "tim-upload-plugin": TIMUploadPlugin,
  });
}

export function im_handleEvents(userId, userSig) {
  // 监听事件，如：
  TIMchat.on(TIM_EVENT.SDK_READY, onReadyStateUpdate);

  TIMchat.on(TIM_EVENT.SDK_NOT_READY, onReadyStateUpdate);

  TIMchat.on(TIM_EVENT.KICKED_OUT, kicked_out);

  TIMchat.on(TIM_EVENT.NET_STATE_CHANGE, net_state_change);
}

//事件卸载
export function eventOff(userId, userSig) {
  console.log("取消监听事件");
  TIMchat.off(TIM_EVENT.SDK_READY, onReadyStateUpdate);

  TIMchat.off(TIM_EVENT.SDK_NOT_READY, onReadyStateUpdate);

  TIMchat.off(TIM_EVENT.KICKED_OUT, kicked_out);

  TIMchat.off(TIM_EVENT.NET_STATE_CHANGE, net_state_change);
}

//公共函数（on监听、记得卸载off）

function kicked_out(event) {
  // 收到被踢下线通知
  // event.name - TIM_EVENT.KICKED_OUT
  // event.data.type - 被踢下线的原因，例如 :
  //   - TencentCloudChat.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
  //   - TencentCloudChat.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
  //   - TencentCloudChat.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢
  //   - TencentCloudChat.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出。)
  console.log('退出登录')
  // console.log(event);
  let content = '您的账号在其他地方登录了，请重新登录!'
  try{
    switch(event.data.type){
      case TencentCloudChat.TYPES.KICKED_OUT_MULT_ACCOUNT:
        content = '你的账号在其他地方登录了!';
        break;
      case TencentCloudChat.TYPES.KICKED_OUT_MULT_DEVICE:
        content = '多终端登录被踢';
        break;
      case TencentCloudChat.TYPES.KICKED_OUT_USERSIG_EXPIRED:
        content = '签名过期被踢';
        break;
      case TencentCloudChat.TYPES.KICKED_OUT_REST_API:
        content = 'REST API kick 接口踢出';
        break;
    }
  }catch(e){
    //TODO handle the exception
  }
  MessageBox.alert(content, '提示', {
    confirmButtonText: '退出登录',
    showClose: false,
    callback: (action) => {
      if (action == 'confirm') {
        eventHub.$emit('logout',true) //退出登录
      }
    },
  }).then(r => {})
}

function net_state_change(event) {
  // 网络状态发生改变
  // event.name - TIM_EVENT.NET_STATE_CHANGE
  // event.data.state 当前网络状态，枚举值及说明如下：
  //   - TencentCloudChat.TYPES.NET_STATE_CONNECTED - 已接入网络
  //   - TencentCloudChat.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
  //   - TencentCloudChat.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
}
function onTotalUnreadMessageCountUpdated(event) {
  console.log("未读消息总数::::",event.data); // 当前单聊和群聊会话的未读总数
  $store.commit("chat/set_unreadCount", event.data); //设置未读消息总数
}

function onReadyStateUpdate({name}){
  // event.name - TIM_EVENT.SDK_READY
  let isSDKReady = name === TIM_EVENT.SDK_READY;
  $store.commit("chat/set_sdkReady", isSDKReady);
  //如果是sdk_ready
  if(isSDKReady){
    console.log("sdk_ready::::");
    //监听收到消息，这里的收到消息仅处理自定义消息（全局消息）
    TIMchat.on(TIM_EVENT.MESSAGE_RECEIVED, message_received);
    //监听消息列表变动
    TIMchat.on(TIM_EVENT.CONVERSATION_LIST_UPDATED, conversationListUpdated)
    //监听会话未读总数
    TIMchat.on(TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, onTotalUnreadMessageCountUpdated);

    conversationListUpdated()//先直接获取一次会话列表
    //监听信令消息
    onSignalingListener()
  }else{
    console.log("sdk_not_ready::::");
    // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
    // event.name - TIM_EVENT.SDK_NOT_READY
    //卸载消息监听
    TIMchat.off(TIM_EVENT.MESSAGE_RECEIVED, message_received);
    //卸载对话监听
    TIMchat.off(TIM_EVENT.CONVERSATION_LIST_UPDATED, conversationListUpdated)
    //卸载信令监听
    offSignalingListener()
  }
}
