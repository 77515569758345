/* 全局mixin */
import {mapGetters, mapState} from 'vuex'
export default  {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      user_info: state => state.user.user_info
    }),
    ...mapGetters([
      'app_location',
    ])
  }
}
