import base from "@/api/base";

const getters = {
  getPayfor: (state) => state.payforParams,
  getPayment: (state) => state.paymentParams,
  system_conversation: state => {
    let result = {}
    try {
      //取出system用户会话
      result = state.chat.allConversation.find(item => item.conversationID === base.IM_systemId)
    } catch (e) {

    }
    return result
  },
  app_location: state => {
    // 默认定位 凯歌科技
    let result = {
      longitude: '113.850402',//经度
      latitude: '34.003321',//纬度
    }
    const user_location = state.user.location
    //赋值经纬度
    if(user_location.lon){
      result.longitude = user_location.lon
      try {
        result.longitude = user_location.lon || user_location.rectangle[0].split(',')[0];
      }catch (e) {

      }
    }
    if(user_location.lat){
      result.latitude = user_location.lat
      try {
        result.latitude = user_location.lat || user_location.rectangle[0].split(',')[1]
      }catch (e) {

      }
    }

    return result
  }
}

export default getters
