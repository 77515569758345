import axios from 'axios'
import {
  Message
} from "element-ui";
import $store from '@/store'
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// 进度条动画
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  showSpinner: false
})

// 设置axios超时时间
axios.defaults.timeout = 30000;
// 环境的切换
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = 'https://api.ilovehealth.com.cn';
} else if (process.env.NODE_ENV == 'debug') {
  axios.defaults.baseURL = 'https://api.ilovehealth.com.cn';
} else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = 'https://api.ilovehealth.com.cn';
}
// post请求头的设置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截 有些请求是需要用户登录之后才能访问的，或者post请求的时候，我们需要序列化我们提交的数据。这时候，我们可以在请求被发送之前进行一个拦截，从而进行我们想要的操作。
// 先导入vuex,因为我们要使用到里面的状态对象
// vuex的路径根据自己的路径去写
// import store from '@/store/index';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = store.state.token;
    // const token = window.localStorage.getItem('office_web:token')
    // token && (config.headers.Authorization = token);
    NProgress.done()
    return config;
  },
  error => {
    return Promise.error(error);
  })

/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
// import axios from 'axios';
import router from '../router/index'
import eventHub from '@/utils/eventHub'
// import store from '../store/index';

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Message({
    message: msg,
    duration: 1000,
    forbidClick: true
  });
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  eventHub.$emit('logout', 'loginErr')
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
    Message.error({
        message: other,
        center: true,
        showClose: true,
        duration: 1000
      })
      toLogin();
      break;
    // 403 token过期 清除token并跳转登录页
    case 403:
      Message.error({
        message: other,
        center: true,
        showClose: true,
        duration: 1000
      })
      tip('登录过期，请重新登录');
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      tip('请求的资源不存在');
      break;
    default:
      Message.error({
        message: other,
        center: true,
        showClose: true,
        duration: 1000
      })
  }
}

// 创建axios实例
var instance = axios.create({
  timeout: 1000 * 300
});
// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.defaults.headers['S-Platform'] = 'pc'
let noPrompt = false;
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    // const token = store.state.token;
    const token = window.localStorage.getItem('office_web:token')
    token && (config.headers.Authorization = token);
    if(window.sessionStorage.getItem('appId')){
      const appId = window.sessionStorage.getItem('appId')
      appId && (config.headers.appId = appId);
    }
    if(config.data && config.data.noPrompt){
      noPrompt = config.data.noPrompt;
    }
    return config;
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  // res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
  res => {
    if(res.status === 200) {
      if (res.data.status === 4002) {
        eventHub.$emit('logout', 'loginErr')
      } else {
        return Promise.resolve(res);
      }
    } else {
      return Promise.reject(res)
    }
  },
  // 请求失败
  error => {
    const {
      response
    } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      if(!noPrompt){
        errorHandle(response.status, response.data.message);
      }else {
        noPrompt = false;
      }

      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
         store.commit('changeNetwork', false);
      } else {
        // return Promise.reject(error);
        return tip('网络错误');
      }
    }
  });
export default instance;
